import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const Service_one = ({ location }) => {
  const { state = {} } = location;
  return (
    <Layout>
      <div id="main">
        <div className="services_section_banner">
          <div
            className="services_banner"
            style={{ backgroundImage: `url(../images/levenmas/comercio_exte.jpg)` }}
          >
            <h1>Servicio de Consultoría en Comercio Exterior</h1>
          </div>
        </div>
        <div className="inner">
          <div className="services_section_1"></div>
          <div className="card">
            <div className="card__content">
              <h1 style={{ color: '#000000', fontSize: 20 }}>
                Servicio de Consultoría en Comercio Exterior
              </h1>

              <ul style={{ color: '#000000' }}>
                <li>Registro en el Padrón de Importadores</li>
                <li>Encargos Conferidos</li>
                <li>Impuestos y Derechos de comercio exterio</li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Service_one;
